<template>
  <b-modal
    :id="id"
    ok-variant="warning"
    ok-title="Yes, I understand"
    modal-class="modal-warning"
    centered
    title="Add to Cart"
    @ok="$emit('ok')"
    @hide="$emit('hide')"
  >
    <b-card-text>
      {{ message }}
    </b-card-text>
  </b-modal>
</template>

<script>
import { BCardText, BModal } from 'bootstrap-vue'

export default {
    name: 'CartConflictModal',
    components: {
        BModal, BCardText,
    },
    props: {
        message: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            default: '',
            required: true,
        },
    },
}
</script>
