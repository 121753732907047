<template>
  <!-- Brands -->
  <div>
    <div class="sticky-brand-filters">
      <div class="d-flex browse-nav-bar">
        <div
          class="browse-nav-item"
          @click="discoverBrands"
        >
          BRANDS
        </div>
        <div class="browse-nav-item active">
          PRODUCTS
        </div>
      </div>
      <!-- Searchbar -->
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              placeholder="Search Product"
              class="search-product pl-3"
              @input="onSearch"
            />
          </b-input-group>
          <feather-icon
            icon="SearchIcon"
            size="18"
            class="position-absolute explore-search-icon"
          />
        </b-col>
      </b-row>
      <!-- Dropdown filters -->
      <div v-if="filterSetupDone">
        <div class="row m-0 mt-1">
          <div class="col-2 p-0">
            <b-dropdown
              class="filter-dropdown"
              variant="outline-none"
              block
              no-caret
              menu-class="w-100 filter-dropdown-menu scroll-bar"
              toggle-class="filter-dropdown-toggle"
              no-flip
            >
              <template #button-content>
                <div
                  class="d-flex justify-content-between"
                  :class="{ 'has-value': sortBy && sortBy.value }"
                >
                  <span> {{ sortBy ? sortBy.text : 'Sort by' }} </span>
                  <feather-icon icon="ChevronDownIcon" />
                </div>
              </template>
              <b-dropdown-item
                v-for="option in sortByOptions"
                :key="option.value"
                :active="option.value === sortBy.value"
                @click="sortBy = option"
              >
                <div>
                  <span>
                    {{ option.text }}
                  </span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="col-3 p-0">
            <b-dropdown
              class="filter-dropdown ml-8-px"
              variant="outline-none"
              block
              no-caret
              menu-class="w-100 filter-dropdown-menu scroll-bar"
              toggle-class="filter-dropdown-toggle"
              no-flip
            >
              <template #button-content>
                <div
                  class="d-flex justify-content-between"
                  :class="{
                    'has-value': getFilterOptionsCount(filters.availabilityDates),
                  }"
                >
                  <span>
                    Delivery date
                    {{
                      getFilterOptionsCount(filters.availabilityDates) &&
                        `(${getFilterOptionsCount(filters.availabilityDates)})`
                    }}
                  </span>
                  <feather-icon icon="ChevronDownIcon" />
                </div>
              </template>
              <template
                v-if="
                  filterOptions.availabilityDates &&
                    filterOptions.availabilityDates.length
                "
              >
                <b-dropdown-item
                  v-for="availabilityDate in filterOptions.availabilityDates"
                  :key="availabilityDate"
                  :active="filters.availabilityDates.includes(availabilityDate)"
                  @click="filterByDropdown('availabilityDates', availabilityDate)"
                >
                  <div class="d-flex justify-content-between">
                    <span>
                      {{ availabilityDate }}
                    </span>
                    <span>
                      <tick-icon
                        v-if="
                          filters.availabilityDates.includes(availabilityDate)
                        "
                      />
                    </span>
                  </div>
                </b-dropdown-item>
              </template>
              <template v-else>
                <b-dropdown-item> No dates available </b-dropdown-item>
              </template>
            </b-dropdown>
          </div>
          <div class="col-4 p-0">
            <b-dropdown
              class="filter-dropdown ml-8-px"
              variant="outline-none"
              block
              no-caret
              menu-class="w-100 filter-dropdown-menu scroll-bar"
              toggle-class="filter-dropdown-toggle"
              no-flip
            >
              <template #button-content>
                <div
                  class="d-flex justify-content-between"
                  :class="{
                    'has-value': getFilterOptionsCount(filters.departments),
                  }"
                >
                  <span>
                    Departments
                    {{
                      getFilterOptionsCount(filters.departments) &&
                        `(${getFilterOptionsCount(filters.departments)})`
                    }}
                  </span>
                  <feather-icon icon="ChevronDownIcon" />
                </div>
              </template>
              <b-dropdown-item
                v-for="department in filterOptions.departments"
                :key="department"
                :active="filters.departments.includes(department)"
                @click="filterByDropdown('departments', department)"
              >
                <div class="d-flex justify-content-between">
                  <span>
                    {{ department }}
                  </span>
                  <span>
                    <tick-icon v-if="filters.departments.includes(department)" />
                  </span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="col-3 p-0">
            <b-dropdown
              class="filter-dropdown ml-8-px"
              variant="outline-none"
              block
              no-caret
              menu-class="w-100 filter-dropdown-menu scroll-bar"
              toggle-class="filter-dropdown-toggle"
              no-flip
            >
              <template #button-content>
                <div
                  class="d-flex justify-content-between"
                  :class="{ 'has-value': getFilterOptionsCount(filters.colors) }"
                >
                  <span>
                    Colours
                    {{
                      getFilterOptionsCount(filters.colors) &&
                        `(${getFilterOptionsCount(filters.colors)})`
                    }}
                  </span>
                  <feather-icon icon="ChevronDownIcon" />
                </div>
              </template>
              <b-dropdown-item
                v-for="color in filterOptions.colors"
                :key="color"
                :active="filters.colors.includes(color)"
                @click="filterByDropdown('colors', color)"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <div class="d-flex align-items-center">
                      <div
                        v-if="color"
                        class="option-color"
                        :class="classForWhiteColor(color)"
                        :style="'background:' + getColorCode(color)"
                      />
                      <div class="ml-10-px option-label">
                        {{ color }}
                      </div>
                    </div>
                  </div>
                  <span>
                    <tick-icon v-if="filters.colors.includes(color)" />
                  </span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="row m-0 mt-1">
          <div class="col-2 p-0">
            <b-dropdown
              class="filter-dropdown"
              variant="outline-none"
              block
              no-caret
              menu-class="w-100 filter-dropdown-menu scroll-bar"
              toggle-class="filter-dropdown-toggle"
              no-flip
            >
              <template #button-content>
                <div
                  class="d-flex justify-content-between"
                  :class="{ 'has-value': getFilterOptionsCount(filters.sizes) }"
                >
                  <span>
                    Sizes
                    {{
                      getFilterOptionsCount(filters.sizes) &&
                        `(${getFilterOptionsCount(filters.sizes)})`
                    }}
                  </span>
                  <feather-icon icon="ChevronDownIcon" />
                </div>
              </template>
              <b-dropdown-item
                v-for="size in filterOptions.sizes"
                :key="size"
                :active="filters.sizes.includes(size)"
                @click="filterByDropdown('sizes', size)"
              >
                <div class="d-flex justify-content-between">
                  <span>
                    {{ size }}
                  </span>
                  <span>
                    <tick-icon v-if="filters.sizes.includes(size)" />
                  </span>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="col-4 p-0">
            <b-dropdown
              ref="rs_filter_dropdown"
              class="filter-dropdown ml-8-px"
              variant="outline-none"
              block
              no-caret
              menu-class="w-100 filter-dropdown-menu scroll-bar"
              toggle-class="filter-dropdown-toggle"
              no-flip
            >
              <template #button-content>
                <div
                  class="d-flex justify-content-between"
                  :class="{ 'has-value': !isEmptyRetailPrice }"
                >
                  <span>
                    {{
                      isEmptyRetailPrice ? 'Retail Prices' : selectedRetailPrice
                    }}
                  </span>
                  <feather-icon icon="ChevronDownIcon" />
                </div>
              </template>
              <b-dropdown-text class="range-filter-container d-flex flex-column">
                <div class="d-flex flex-row inputs">
                  <div class="input d-flex flex-row align-items-center">
                    <span class="label">from</span>
                    <b-form-input
                      v-model="retailPrice.retailPriceMin"
                      class="ml-8-px filter-dropdown-toggle"
                    />
                  </div>
                  <div class="input d-flex flex-row align-items-center ml-1">
                    <span class="label">to</span>
                    <b-form-input
                      v-model="retailPrice.retailPriceMax"
                      class="ml-8-px filter-dropdown-toggle"
                    />
                  </div>
                </div>
                <div class="mt-1">
                  <b-button
                    variant="custom-primary"
                    block
                    @click="applyRange(retailPrice)"
                  >
                    Accept
                  </b-button>
                </div>
              </b-dropdown-text>
            </b-dropdown>
          </div>
          <div class="col-4 p-0">
            <b-dropdown
              ref="ws_filter_dropdown"
              class="filter-dropdown ml-8-px"
              variant="outline-none"
              block
              no-caret
              menu-class="w-100 filter-dropdown-menu scroll-bar"
              toggle-class="filter-dropdown-toggle"
              no-flip
            >
              <template #button-content>
                <div
                  class="d-flex justify-content-between"
                  :class="{ 'has-value': !isEmptyWsPrice }"
                >
                  <span>
                    {{ isEmptyWsPrice ? 'Wholesale Prices' : selectedWsPrice }}
                  </span>
                  <feather-icon icon="ChevronDownIcon" />
                </div>
              </template>
              <b-dropdown-text class="range-filter-container d-flex flex-column">
                <div class="d-flex flex-row inputs">
                  <div class="input d-flex flex-row align-items-center">
                    <span class="label">from</span>
                    <b-form-input
                      v-model="wholesalePrice.wholesalePriceMin"
                      class="ml-8-px filter-dropdown-toggle"
                    />
                  </div>
                  <div class="input d-flex flex-row align-items-center ml-1">
                    <span class="label">to</span>
                    <b-form-input
                      v-model="wholesalePrice.wholesalePriceMax"
                      class="ml-8-px filter-dropdown-toggle"
                    />
                  </div>
                </div>
                <div class="mt-1">
                  <b-button
                    variant="custom-primary"
                    block
                    @click="applyRange(wholesalePrice)"
                  >
                    Accept
                  </b-button>
                </div>
              </b-dropdown-text>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="totalProducts"
      class="products-section"
    >
      <div
        :class="{
          'guest-user': isGuest,
          'brand-user': isBrand,
          'retailer-user': isRetailer,
        }"
      >
        <div class="body-content-overlay" />
        <!-- Searchbar -->
        <!-- Products -->
        <div
          v-for="(collection, index) in collections"
          :key="index"
        >
          <div
            v-if="collection.collectionId && collection.products.length"
            class="brand-cluster-header d-flex justify-content-between"
          >
            <b-link
              class="d-flex align-items-center cursor-pointer"
              :to="{ name: 'brand/detail', params: { brandId: collection.brandId }}"
            >
              <b-img-lazy
                v-if="collection.logo"
                :src="collection.logo"
                :alt="collection.entityName"
                class="cluster-logo"
                @error.native="imageLoadError"
              />
              <div class="ml-10-px d-flex align-items-center cluster-name">
                <span class="mr-5-px">
                  {{ collection.entityName }}
                </span>
                -
                <b-link
                  class="ml-5-px"
                  :to="{name: 'collection/products', params: { collectionId: collection.products[0].collectionId }}"
                >{{ collection.collectionName }}
                </b-link>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="16"
                />
              </div>
            </b-link>
          </div>
          <section
            v-if="collection.products.length"
            ref="cardColumns"
            class="product-item"
          >
            <product-card
              v-for="(product, key) in collection.products"
              :key="`${product._id}-${key}`"
              :product="product"
            />
          </section>
        </div>
        <section
          v-if="continueLoading"
          class="mt-4"
        >
          <b-row>
            <b-col
              cols="12"
              class="text-center"
            >
              <b-spinner
                ref="loadingIcon"
                class="loading-icon"
                label="Loading..."
              />
            </b-col>
          </b-row>
        </section>
      </div>
    </div>
    <div
      v-if="isLoaded && !totalProducts"
      class="mt-5 pt-3"
    >
      <empty-products-message />
    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-detached-left">
      <shop-filter
        v-if="Object.values(filterCardOptions).length"
        :filters="filters"
        :filter-options="filterCardOptions"
        :total-products="totalProducts"
        :mq-shall-show-left-sidebar="false"
        :is-explore="true"
        target="null"
        @update-filters="fetchShopProducts"
      />
    </portal>
  </div>
</template>

<script>
import {
  elementInViewport,
  imageLoadError,
} from '@core/utils/utils'
import { classForWhiteColor, getColorCode } from '@/product-colors'
import {
  BCol,
  BInputGroup,
  BFormInput,
  BImgLazy,
  BRow,
  VBTooltip,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BDropdownText,
  BButton,
  BLink,
} from 'bootstrap-vue'
import { useEcommerceUi } from '@/views/apps/useEcommerce'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import _ from 'lodash'
import TickIcon from '@/@core/assets/svg-components/TickIcon.vue'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'
import { FETCH_COLLECTION_PRODUCT_FILTERS, FETCH_DISCOVER_PRODUCTS } from '@/store/modules/collection.module'
import UserRoleMixin from '../../UserRoleMixin.vue'
import ShopFilter from '../../shop/ShopFilter.vue'
import ProductCard from './ProductCard.vue'
import { useShopFiltersSortingAndPagination } from '../../shop/useECommerceShop'
import EmptyProductsMessage from '../../shop/EmptyProductsMessage.vue'

export default {
  name: 'DiscoverProducts',
  components: {
    ShopFilter,
    BImgLazy,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    FeatherIcon,
    ProductCard,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BDropdownText,
    BButton,
    TickIcon,
    EmptyProductsMessage,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [UserRoleMixin],
  data() {
    return {
      totalProducts: 0,
      filterOptions: {},
      filters: {
        search: '',
        page: 0,
        limit: 2,
        sortBy: this.sortBy.value,
        isPremium: false,
      },
      retailPrice: {
        retailPriceMin: null,
        retailPriceMax: null,
      },
      wholesalePrice: {
        wholesalePriceMin: null,
        wholesalePriceMax: null,
      },
      filterCardOptions: {},
      collections: [],
      searchingText: '',
      classForWhiteColor,
      getColorCode,
      imageLoadError,
      isLoading: false,
      continueLoading: true,
      filterSetupDone: false,
      isLoaded: false,
      discoverBrandsRouteName: '',
    }
  },
  computed: {
    isEmptyRetailPrice() {
      return !(
        this.retailPrice.retailPriceMin || this.retailPrice.retailPriceMax
      )
    },
    isEmptyWsPrice() {
      return !(
        this.wholesalePrice.wholesalePriceMin
        || this.wholesalePrice.wholesalePriceMax
      )
    },
    selectedRetailPrice() {
      return `${
        this.retailPrice.retailPriceMin
      }-${this.retailPrice.retailPriceMax}`
    },
    selectedWsPrice() {
      return `${
        this.wholesalePrice.wholesalePriceMin
      }-${this.wholesalePrice.wholesalePriceMax}`
    },
  },
  watch: {
    sortBy: {
      handler(item) {
        this.filters.sortBy = item.value
        this.filters.page = 0
        this.fetchShopProducts()
      },
      deep: true,
    },
  },
  created() {
    if (this.$route.query.isPremium === 'true') {
      this.filters.isPremium = true
      this.discoverBrandsRouteName = 'premiumbrands'
    } else {
      this.discoverBrandsRouteName = 'explore'
    }
    this.fetchShopFilters()
    this.fetchShopProducts()
  },
  mounted() {
    window.addEventListener('scroll', this.onScrollLoader)
  },
  methods: {
    applyRange(range = {}) {
      this.filters = {
        ...this.filters,
        ...range,
        page: 0,
      }
      this.fetchShopProducts(this.filters)
      this.$refs.rs_filter_dropdown.hide()
      this.$refs.ws_filter_dropdown.hide()
    },
    getFilterOptionsCount(options = null) {
      return options?.length || ''
    },
    filterByDropdown(key = '', value = '') {
      this.filters.page = 0
      if (key && value) {
        if (!this.filters[key].includes(value)) {
          this.filters[key].push(value)
        } else {
          this.filters[key] = this.filters[key].filter(
            option => option !== value,
          )
        }
      }
      this.fetchShopProducts(this.filters)
    },
    discoverBrands() {
      this.$router.push({ name: this.discoverBrandsRouteName })
      analytics.track(constants.TRACKS.ACTIONS.RETAILER_CLICKS_DISCOVER_BRANDS)
    },
    calculateImageRatio(imageClassId) {
      const element = this.$el.getElementsByClassName(imageClassId)
      if (element.length) {
        const width = element[0].naturalWidth
        const height = element[0].naturalHeight
        // Calculate the image resolution and place the position of the image
        // at every load of the image

        if (!(height <= 1 && width <= 1)) {
          const ratio = height / width
          if (ratio <= 1.48) {
            element[0].classList.add('h-100')
          } else {
            element[0].classList.remove('h-100')
          }
        }
      }
    },
    onScrollLoader() {
      if (!this.isLoading && elementInViewport(this.$refs?.loadingIcon)) {
        this.isLoading = true
        this.filters.page++
        this.fetchShopProducts()
      }
    },
    getTotalProductsCount() {
      let count = 0
      this.collections.forEach(collection => {
        count += collection.products?.length || 0
      })
      this.totalProducts = count
    },
    setColors(collections = []) {
      collections.forEach(collection => {
        collection.products.forEach(product => {
          const productColors = []
          const colors = []
          product.items?.forEach(item => {
            if (colors.indexOf(item.color) < 0) {
              colors.push(item.color)
              productColors.push({
                color: item.color,
                colorFamily: item.colorFamily,
              })
            }
          })
          product.colors = productColors
        })
      })
      return collections
    },
    onSearch: _.debounce(function onSearch(val) {
      this.filters.search = val
      this.filters.page = 0
      this.fetchShopProducts()
    }, 500),

    // ---------------------------------------- //

    fetchShopProducts(newFilter) {
      this.isLoaded = false
      if (newFilter && typeof newFilter === 'object') {
        this.filters = newFilter
        this.filters.page = 0
      }
      const fetchParam = {
        page: this.filters.page,
      }
      for (const key in this.filters) {
        if (this.filters.hasOwnProperty(key)) {
          if (Array.isArray(this.filters[key]) && this.filters[key].length) {
            fetchParam[key] = this.filters[key].join(',')
          } else {
            fetchParam[key] = this.filters[key]
          }
        }
      }
      this.$store.dispatch(FETCH_DISCOVER_PRODUCTS, fetchParam)
        .then(response => {
          const result = response.data.data
          if (!this.filters.page) {
            this.collections = []
          }
          this.collections = [
            ...this.collections,
            ...result,
          ]
          if (result.count > this.collections.length) {
            this.continueLoading = true
          } else {
            this.continueLoading = false
          }
          this.isLoading = false
          this.collections = this.setColors(this.collections)
          this.isLoaded = true
          this.getTotalProductsCount()
        })
    },
    fetchShopFilters() {
      this.$store.dispatch(FETCH_COLLECTION_PRODUCT_FILTERS)
      .then(res => {
        this.filterOptions = res.data.data[0]
        this.filterCardOptions = {
          gender: this.filterOptions.gender,
          categories: this.filterOptions.categories,
          subCategories: this.filterOptions.subCategories,
        }
        this.filterOptions.colors = this.filterOptions?.colors?.sort()
        for (const key in this.filterOptions) {
          if (this.filterOptions.hasOwnProperty(key)) {
            this.filters[key] = []
            if (key.indexOf('PriceMax') > -1) {
              this.filterOptions[key] = Math.round(this.filterOptions[key]) + 1
            }
            if (key.indexOf('PriceMin') > -1) {
              this.filterOptions[key] = Math.round(this.filterOptions[key]) - 1
            }
          }
        }
        if (this.filterOptions) {
          this.filterOptions.retailPrice = [
            this.filterOptions.retailPriceMin,
            this.filterOptions.retailPriceMax,
          ]
          this.filterOptions.wholesalePrice = [
            this.filterOptions.wholesalePriceMin,
            this.filterOptions.wholesalePriceMax,
          ]
        }
      })
      this.filterSetupDone = true
    },
  },
  setup() {
    const { parseProductImage } = useEcommerceUi()
    const { sortBy, sortByOptions } = useShopFiltersSortingAndPagination()

    return {
      parseProductImage,
      sortBy,
      sortByOptions,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~@core/scss/base/pages/app-shop.scss';
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';

.product-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 8px;
  .product-card {
    margin-bottom: 8px;
  }
}

@media (max-width: 1199.98px) {
  .product-item {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .product-item {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 567px) {
  .product-item {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 330px) {
  .product-item {
    grid-template-columns: 1fr;
  }
}

.brand-cluster-header {
  border-bottom: 1px solid $body-color;
  padding-bottom: 10px;
  margin-bottom: 16px;
  margin-top: 28px;

  .cluster-logo {
    width: 69px;
    height: 69px;
    border: 0.936249px solid $alice-blue;
    box-shadow: 3.55213px 3.55213px 11.1004px $cluster-logo-box-shadow;
    border-radius: 36.48px;
  }

  .cluster-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $body-color;
  }

  .go-to-brand-page {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: $dark-blue;
  }
}

.option-color {
  height: 16px;
  width: 16px;
  border-radius: 50%;
}

.option-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: $body-color;
}
</style>
