<template>
  <b-link
    class="product-card cursor-pointer"
    :class="{'padding-bottom-card': showActions}"
    :to="{name: 'product-detail',params: {
      id: product._id,
      collectionId: product.collectionId,
    }}"
  >
    <div class="image-container">
      <b-card-img-lazy
        class="product-image"
        :alt="`${product.name}-${product._id}`"
        fluid-grow
        :src="parseProductImage(product)"
        :class="`img-${product._id}`"
        @load.native="calculateImageRatio(`img-${product._id}`)"
        @error.native="imageLoadError"
      />
    </div>
    <div class="details-container">
      <div class="product-name">
        {{ product.name }}
      </div>
      <div class="product-style-code">
        {{ product.styleCode }}
      </div>
      <div class="d-flex flex-row product-color-container">
        <div
          v-for="productColors in product.colors"
          :key="productColors.color"
          class="product-color-item"
        >
          <div
            v-if="getColorCode(productColors.colorFamily)"
            v-b-tooltip.hover.left="productColors.color"
            class="product-color"
            :class="classForWhiteColor(productColors.colorFamily)"
            :style="'background:' + getColorCode(productColors.colorFamily)"
          />
        </div>
      </div>
      <div
        v-if="product.price"
        class="product-prices d-flex justify-content-between"
      >
        <div>WSP:</div>
        <div>
          {{ formatCurrency(product.price, product.currency) }}
        </div>
      </div>
    </div>
    <div
      v-if="showActions"
      class="actions-container d-flex"
    >
      <b-button
        variant="light"
        class="wishlist-button border-0"
        @click.stop="handleClickWishlist(product)"
      >
        <feather-icon
          icon="HeartIcon"
          size="14"
          :class="{ 'text-danger fill-danger': isInWishlist }"
        />
      </b-button>
      <b-button
        variant="custom-primary"
        class="cart-button border-0"
        block
        @click.stop="handleClickCart(product)"
      >
        <span v-if="isInCart"> View In Cart </span>
        <feather-icon
          v-else
          icon="ShoppingCartIcon"
          size="14"
        />
      </b-button>
    </div>
    <div>
      <cart-conflict-modal
        :id="cartConflictModalId"
        :message="cartConflictMessage"
        @ok="handleClickCart(currentCartProduct, true)"
        @hide="$bvModal.show(cartConflictModalId)"
      />
    </div>
  </b-link>
</template>

<script>
import {
 BButton, BCardImgLazy, VBModal, VBTooltip, BLink,
} from 'bootstrap-vue'
import { useEcommerceUi } from '@/views/apps/useEcommerce'
import { utils } from '@kingpin-global/kingpin-utils-frontend'
import { classForWhiteColor, getColorCode } from '@/product-colors'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import ProductImageMixin from '@/views/mixins/ProductImageMixin.vue'
import CartConflictModal from '../../checkout/CartConflictModal.vue'

const { formatCurrency } = utils

export default {
  name: 'ProductCard',
  components: {
    BCardImgLazy,
    BButton,
    FeatherIcon,
    CartConflictModal,
    BLink,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  mixins: [ProductImageMixin],
  props: {
    product: {
      type: Object,
      default() {
        return {}
      },
    },
    showActions: {
      type: Boolean,
      default() {
        return false
      },
    },
  },
  data() {
    return {
      formatCurrency,
      getColorCode,
      classForWhiteColor,
      isInWishlist: !!this.product.isInWishlist,
      isInCart: !!this.product.isInCart,
      currentCartProduct: {},
      cartConflictMessage: '',
      cartConflictModalId: `modal-discover-cart-conflict-${this.product._id}`,
    }
  },
  methods: {
    handleClickWishlist(product) {
      const payload = {
        products: [{ productId: product._id }],
      }
      this.$kpRequest({ ...this.$kpEndpoint.wishlist.create, payload })
        .then(res => {
          this.isInWishlist = true
          apiToastSuccess(res.data.message)
        })
        .catch(err => {
          apiToastError(err)
        })
    },
    handleClickCart(product, shouldProceed = false) {
      this.currentCartProduct = product
      const queryParams = {}
      if (shouldProceed) {
        queryParams.isConfirmed = true
      }
      if (this.isInCart) {
        this.$router.push({ path: '/checkout' })
      } else {
        const payload = {
          products: [
            { productId: product._id, skuId: product.items[0].sku, value: 0 },
          ],
        }
        this.$kpApi
          .put(this.$kpEndpoint.cart.add, payload, { params: queryParams })
          .then(res => {
            this.isInCart = true
            apiToastSuccess(res.data.message)
          })
          .catch(err => {
            if (err.response.status === 425) {
              this.cartConflictMessage = err.response.data.message || err.message
              this.$bvModal.show(this.cartConflictModalId)
            } else {
              this.currentCartProduct = {}
              apiToastError(err)
            }
          })
      }
    },
  },
  setup() {
    const { parseProductImage } = useEcommerceUi()
    return {
      parseProductImage,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-ecommerce.scss';

.product-card {
  position: relative;
  background: $white;
  border: 1px solid $dashboard-analytics-banner-border;
  border-radius: 6px;

  &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 25px 0 rgba($black, 0.25);
  }

  .image-container {
    width: 100%;
    aspect-ratio: 2/3;

    .product-image {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  .details-container {
    padding: 10px;
    .product-name {
      min-height: 40px;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: $body-color;
    }

    .product-style-code {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: $body-color;
    }

    .product-color-container {
      .product-color-item {
        margin: 4px 4px 4px 0;
        display: flex;
        align-items: center;
        background-color: $white;
        height: 20px;
        width: 20px;
        border-radius: 50%;
      }
    }

    .product-prices {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: $body-color;
    }
  }

  .actions-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    .wishlist-button {
      border-radius: 0px 0px 0px 6px;
      background: $wishlist-btn-background;
    }

    .cart-button {
      border-radius: 0px 0px 6px 0px;
    }
  }
}

.padding-bottom-card {
  padding-bottom: 37px;
}

.fill-danger {
  fill: $danger;
}
</style>
